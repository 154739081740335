import { getWidgetStageData } from './components/ricosviewer/manifest';
import {
  DATA_BINDING_APP_DEF_ID,
  RICH_CONTENT_ROLE,
  CONNECT_TO_DATA_EV_ID,
  EDIT_CONTENT_EV_ID,
  EDIT_CONTENT_ACTION_ID,
  TOKEN,
} from './consts';
import { EditorReadyFn, Experiments } from '@wix/yoshi-flow-editor';
import { EventType } from '@wix/platform-editor-sdk';
import type { ComponentRef } from '@wix/platform-editor-sdk';

type ContextType = {
  experiments: Experiments;
  t: (key: string) => string;
};

let context: ContextType;

export const editorReady: EditorReadyFn = async (
  editorSDK,
  _appDefinitionId,
  platformOptions,
  { experiments, translations: { t } },
) => {
  context = {
    experiments,
    t,
  };
  const fedOpsLogger = platformOptions.monitoring.createFedopsLogger()?.('ricos-viewer-ooi');
  fedOpsLogger.appLoadStarted();
  const dataBindingAPI: any = await editorSDK.document.application.getPublicAPI(TOKEN, {
    appDefinitionId: DATA_BINDING_APP_DEF_ID,
  });

  const updateComponentState = async (componentRef: ComponentRef) => {
    const connections = await editorSDK.document.controllers.listConnections(TOKEN, {
      componentRef,
    });
    connections.map(async connection => {
      if (connection.role === RICH_CONTENT_ROLE) {
        await editorSDK.document.controllers.setState(TOKEN, {
          state: { connected: [componentRef] },
        });
      }
    });
  };

  const allComponents = await editorSDK.document.components.getAllComponents(TOKEN);
  allComponents.map(componentRef => updateComponentState(componentRef));

  editorSDK.addEventListener(EventType.widgetGfppClicked, event => {
    const { id, componentRef } = event.detail;
    if (id === CONNECT_TO_DATA_EV_ID) {
      return dataBindingAPI?.openConnectPanel?.({ componentRef });
    }
    if (id === EDIT_CONTENT_EV_ID) {
      return dataBindingAPI?.openManageContentPanel?.({
        componentRef,
        actionId: EDIT_CONTENT_ACTION_ID,
        role: RICH_CONTENT_ROLE,
        origin: 'gfpp',
      });
    }
  });

  editorSDK.addEventListener(EventType.componentConnected, async event => {
    const componentRef = event.detail.compRef;
    await editorSDK.document.controllers.setState(TOKEN, {
      state: { connected: [componentRef] },
    });
  });

  editorSDK.addEventListener(EventType.pageAdded, async event => {
    const pageRef = event.detail.pageRef;
    const controllersInPage = await editorSDK.document.controllers.listControllers(
      TOKEN,

      { pageRef, includeTPAWidget: true },
    );
    controllersInPage.map(({ controllerRef }) => updateComponentState(controllerRef));
  });

  editorSDK.addEventListener(EventType.componentDisconnected, async event => {
    const componentRef = event.detail.compRef;
    await editorSDK.document.controllers.setState(TOKEN, {
      state: { default: [componentRef] },
    });
    await editorSDK.application.livePreview.refresh(TOKEN, {
      shouldFetchData: false,
      source: EventType.componentDisconnected,
    });
  });

  editorSDK.addEventListener(EventType.widgetPasted, async event => {
    const componentRef = event.detail.componentRef;
    updateComponentState(componentRef);
  });

  fedOpsLogger.appLoaded();
};

export const getAppManifest = () => {
  return {
    controllersStageData: getWidgetStageData(context.t, context.experiments),
  };
};
